import React, { PureComponent } from "react";
import styled from "styled-components";
import HeroTwoColumns from "../HeroTwoColumns";
import { Headline80 } from "../styles/Headings";
import { mediaMedium } from "../styles/variables";
import { gray4 } from "../styles/Colors";
import LinesFromTop from "../LinesFromTop";
import { Body20 } from "../styles/BodyTypes";
import CTALink from "../styles/CTALink";

const Wrapper = styled.div`
  padding: 96px 16px;

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const Row = styled.div`
  margin-bottom: 68px;

  &:first-child {
    margin-bottom: 80px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (${mediaMedium}) {
    margin-bottom: 78px;

    &:first-child {
      margin-bottom: 118px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Decoration = styled.div`
  position: absolute;
  width: 150%;
  right: 80%;
  top: -104px;
  display: none;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const ScamButton = styled.div`
  text-align: center;
  border: 1px solid #b2361a;
  border-radius: 10px;
`;

class CurrentOpenings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      script: null
    };
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://boards.greenhouse.io/embed/job_board/js?for=stackcommerce";
    script.async = true;

    /*global Grnhse*/
    script.onload = () => {
      Grnhse.Iframe.load();
    };

    document.body.appendChild(script);
    this.setState({ script });
  }

  componentWillUnmount() {
    const { script } = this.state;

    if (script) {
      document.body.removeChild(script);
    }
  }

  render() {
    return (
      <HeroTwoColumns
        justify="flex-start"
        columns="calc(100% * 5/12) calc(100% * 5.9/12)"
        background={gray4}
      >
        <Decoration>
          <LinesFromTop skew="20deg" />
        </Decoration>
        <div />
        <Wrapper id="careers">
          <Row>
            <Headline80>CURRENT OPENINGS</Headline80>
          </Row>
          <Row>
            <div id="grnhse_app" />
          </Row>
          <Row>
            <Body20>
              If you don't see any current openings that fit your skillset, feel
              free to send us your resume at{" "}
              <CTALink attrs={{ href: "mailto:careers@stackcommerce.com" }}>
                careers@stackcommerce.com
              </CTALink>
              .
            </Body20>
            <Body20>
              <ScamButton>
                <CTALink attrs={{ to: "/recruiting-scams/" }}>Protect yourself from recruiting scams</CTALink>
              </ScamButton>
            </Body20>
          </Row>
        </Wrapper>
      </HeroTwoColumns>
    );
  }
}

export default CurrentOpenings;
