import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { brandColor2, gray4 } from "../components/styles/Colors";
import HeroTwoColumns from "../components/HeroTwoColumns";
import LinesFromRight from "../components/LinesFromRight";
import { Eyebrows16 } from "../components/styles/Eyebrows";
import { Body28 } from "../components/styles/BodyTypes";
import { mediaMedium } from "../components/styles/variables";
import HeroMetrics from "../components/about/HeroMetrics";
import Values from "../components/about/Values";
import Blog from "../components/about/Blog";
import Perks from "../components/about/Perks";
import PraiseFor from "../components/about/PraiseFor";
import Investors from "../components/about/Investors";
import CurrentOpenings from "../components/about/CurrentOpenings";
import StackHappenings from "../components/about/StackHappenings";
import OurTeam from "../components/about/OurTeam";
import Landings from "../components/about/Landing";
import Lightbox from "../components/about/Lightbox";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Decoration = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 113px;
  left: 25%;
  pointer-events: none;
`;

const WhoWeAre = styled.div`
  padding: 159px 20px 102px;

  @media (${mediaMedium}) {
    padding: 136px 8px 206px;
  }
`;

const About = () => (
  <Layout>
    <Seo
      title="About Our Company | Job Openings"
      description="We’re hiring! Check out our job openings and apply to join a team on a mission to help publishers and brands grow through commerce."
    />

    <Landings
      link={{
        label: "Join the Team",
        attrs: {
          to: "/about/#careers",
          as: AnchorLink
        }
      }}
    />

    <Lightbox />

    <HeroTwoColumns padding="10px 0 0" background={gray4}>
      <WhoWeAre>
        <Eyebrows16 as="h2">WHO WE ARE</Eyebrows16>
        <Body28>
          Stack is a product discovery platform with a touch of grit and a lot
          of heart. We source amazing products, tell captivating stories, and
          ultimately put incredible products in the hands of millions of
          satisfied customers worldwide.
        </Body28>
      </WhoWeAre>
      <div />

      <Decoration>
        <LinesFromRight color={brandColor2} />
      </Decoration>
    </HeroTwoColumns>

    <HeroMetrics />

    <Values />

    <Perks />

    <PraiseFor />

    <Investors />

    <OurTeam />

    <CurrentOpenings />

    <StackHappenings />

    <Blog />
  </Layout>
);

export default About;
