import React from "react";
import styled from "styled-components";
import { mediaMedium } from "./styles/variables";
import { brandColor2 } from "./styles/Colors";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  pointer-events: none;
`;

const Line1 = styled.span`
  @media (${mediaMedium}) {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 150%;
    transform: skewY(${(props) => props.skew ?? "28deg"});
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: ${(props) => props.color ?? brandColor2};
    top: -8px;
    right: 7px;
  }
`;

const Line2 = styled(Line1)`
  @media (${mediaMedium}) {
    top: 18px;
    right: -8px;
  }
`;

const Line3 = styled(Line1)`
  @media (${mediaMedium}) {
    top: 44px;
    right: -23px;
  }
`;

const LinesFromTop = ({ color, skew }) => {
  return (
    <Wrapper>
      <Line1 skew={skew} color={color} />
      <Line2 skew={skew} color={color} />
      <Line3 skew={skew} color={color} />
    </Wrapper>
  );
};

export default LinesFromTop;
