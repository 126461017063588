import React from "react";
import styled from "styled-components";
import { Headline80 } from "../styles/Headings";
import { mediaMedium } from "../styles/variables";
import Container from "../styles/Container";
import Image from "../Image";

import teamStack2023 from "../../images/team/team_stack_2023.jpg";

const Wrapper = styled.div`
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const Headline = styled(Headline80)`
  margin-bottom: 1rem;
`;

const TeamImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const OurTeam = () => {
  return (
    <Wrapper>
      <Container>
        <Headline>Our Team</Headline>
        <TeamImage loading="lazy" alt="Our team" src={teamStack2023} />
      </Container>
    </Wrapper>
  );
};

export default OurTeam;
