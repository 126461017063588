import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import BackgroundImage from "gatsby-background-image";
import { Headline48, Headline96 } from "../styles/Headings";
import { white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import { graphql, useStaticQuery } from "gatsby";

const Background = styled(BackgroundImage)`
  padding: 96px 0;

  &:before,
  &:after {
    background-size: 120% 120% !important;
    background-position: 40% center !important;
  }

  @media (${mediaMedium}) {
    padding: 160px 0;

    &:before,
    &:after {
      background-size: cover !important;
      background-position: center !important;
    }
  }
`;

const MainTitle = styled(Headline48)`
  margin: 0 0 82px;
  font-size: 32px;
  letter-spacing: -0.8px;
  line-height: 40px;

  @media (${mediaMedium}) {
    margin-bottom: 98px;
    font-size: 48px;
    letter-spacing: -1.3px;
    line-height: 1;
  }
`;

const Title = styled(Headline96)`
  margin: 0;

  &:nth-of-type(odd) {
    color: ${white};
  }
`;

const PerksSection = ({ images }) => {
  return (
    <Background fluid={images.file.childImageSharp.fluid}>
      <Container>
        <MainTitle as="h3">OUR PERKS</MainTitle>
        <Title as="p">unlimited vacation</Title>
        <Title as="p">remote friendly</Title>
        <Title as="p">equity plan</Title>
        <Title as="p">company trips</Title>
        <Title as="p">learning budget</Title>
        <Title as="p">health care</Title>
      </Container>
    </Background>
  );
};

const Perks = () => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "77-about-perks.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 1184, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <PerksSection images={images} />;
};

export default Perks;
