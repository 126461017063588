import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import Image from "../Image";
import { graphql, useStaticQuery } from "gatsby";
import { Headline32, Headline80 } from "../styles/Headings";
import { Eyebrows16 } from "../styles/Eyebrows";
import { Body20 } from "../styles/BodyTypes";
import { black1 } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import { FadeIn } from "../styles/animations";
import AnimationWrapper from "../AnimationWrapper";

const Wrapper = styled.div`
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const Row = styled.div`
  padding-bottom: 40px;

  @media (${mediaMedium}) {
    padding-bottom: 96px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 54px;

  @media (${mediaMedium}) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
`;

const Display = styled.div`
  position: relative;
  background-color: ${black1};
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;

  @media (${mediaMedium}) {
    height: 168px;
  }
`;

const Img = styled(Image)`
  flex: 1 1 auto;
  max-width: 80%;
`;

const WrapperSnippet = styled(FadeIn)`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 84px 1fr;
  align-items: center;

  @media (${mediaMedium}) {
    grid-template-columns: 168px 1fr;
  }
`;

const Content = styled.div`
  height: 100%;

  span {
    margin-bottom: 8px;
  }

  p {
    margin-top: 12px;
  }
`;

const data = [
  {
    image: "bestCompany2020",
    year: "2020",
    title: "COMPARABLY",
    content: "Best Perks & Benefits"
  },
  {
    image: "digiDayTech",
    year: "2020",
    title: "DIGIDAY",
    content:
      "Technology Awards Finalist <br/> Best Affiliate Marketing Platform"
  },
  {
    image: "bestPlace2020",
    year: "2020",
    title: "BUILT IN",
    content: "Best Places to Work in Los Angeles"
  },
  {
    image: "digitalCommerce",
    year: "2020",
    title: "Commerce 360",
    content: "Top 1000"
  },
  {
    image: "digiDayTech",
    year: "2019",
    title: "DIGIDAY",
    content: "Technology Awards Winner <br/> Best Ecommerce Content Platform"
  },
  {
    image: "inc5000",
    year: "2019",
    title: "INC",
    content: "5000 List"
  },
  {
    image: "bestCompany2019",
    year: "2019",
    title: "COMPARABLY",
    content: "Best Companies for Perks & Benefits"
  },
  {
    image: "digiDay",
    year: "2019",
    title: "DIGIDAY",
    content: "Future Leaders"
  },
  {
    image: "ey",
    year: "2019",
    title: "ERNST & YOUNG",
    content: "Entrepreneur of the Year Finalist: <br/> Josh Payne"
  },
  {
    image: "bestPlace2019",
    year: "2019",
    title: "BUILT IN",
    content: "Best Places to Work in Los Angeles"
  },
  {
    image: "laBusinessJournalAward",
    year: "2020",
    title: "LA Business Journal",
    content: "100 Fastest Growing Companies"
  },
  {
    image: "bestPlace2021",
    year: "2021",
    title: "BUILT IN",
    content: "Best Places to Work in Los Angeles"
  },
  {
    image: "bestPlace2022",
    year: "2022",
    title: "BUILT IN",
    content: "Best Places to Work in Los Angeles"
  },
  {
    image: "ey",
    year: "2021",
    title: "ERNST & YOUNG",
    content: "Entrepreneur of the Year Finalist: <br/> Josh Payne"
  }
];
const Threshold = [0.4, 0.5, 0.75];

const Snippet = ({ image, year, title, content }) => {
  return (
    <AnimationWrapper threshold={Threshold} component={WrapperSnippet}>
      <Display>
        <Img fluid={image} alt={title} />
      </Display>
      <Content>
        <Eyebrows16 as="span" theme={{ margins: "0" }}>
          {year}
        </Eyebrows16>
        <Headline32 as="h3" theme={{ margins: "0" }}>
          {title}
        </Headline32>
        <Body20
          as="p"
          theme={{ margins: "0" }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Content>
    </AnimationWrapper>
  );
};

const PraiseForSection = ({ images }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Headline80>
            PRAISE FOR <br /> STACK
          </Headline80>
        </Row>
        <Grid>
          {data.map((item, key) => {
            return (
              <Snippet
                key={"praise-snippet-" + key}
                {...item}
                image={images[item.image].childImageSharp.fluid}
              />
            );
          })}
        </Grid>
      </Container>
    </Wrapper>
  );
};

const PraiseFor = () => {
  const images = useStaticQuery(graphql`
    query {
      bestPlace2022: file(
        relativePath: { eq: "86-praise-best-place-2022.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bestPlace2021: file(
        relativePath: { eq: "86-praise-best-place-2021.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bestPlace2020: file(
        relativePath: { eq: "78-praise-best-place-2020.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bestPlace2019: file(
        relativePath: { eq: "78-praise-best-place-2019.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      digitalCommerce: file(
        relativePath: { eq: "78-praise-digital-commerce.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      digiDayTech: file(
        relativePath: { eq: "78-praise-digiday-technology.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      inc5000: file(relativePath: { eq: "78-praise-inc-5000.png" }) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bestCompany2020: file(
        relativePath: { eq: "78-praise-best-company-2020.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bestCompany2019: file(
        relativePath: { eq: "78-praise-best-company-2019.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      digiDay: file(relativePath: { eq: "78-praise-digiday.png" }) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ey: file(relativePath: { eq: "78-praise-ey.png" }) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      laBusinessJournalAward: file(
        relativePath: { eq: "85-praise-LAs-Business-Journal-Award-2020.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 168, maxHeight: 168, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <PraiseForSection images={images} />;
};

export default PraiseFor;
