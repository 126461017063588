import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import MetricSnippet from "../MetricSnippet";
import { black1 } from "../styles/Colors";
import image from "../../images/76-about-metrics.png";
import { mediaMedium } from "../styles/variables";

const Wrapper = styled.div`
  padding: 96px 0;
  background: url("${image}") center bottom no-repeat ${black1};
  background-size: cover;

  @media (${mediaMedium}) {
    padding: 160px 0;
    background-size: 100% 100%;
  }
`;

const Row = styled.div`
  text-align: center;
`;

const Grid = styled(Container)`
  display: grid;
  justify-content: center;
  grid-gap: 64px;

  @media (${mediaMedium}) {
    grid-gap: 88px;
  }
`;

const HeroMetrics = () => {
  return (
    <Wrapper>
      <Grid>
        <Row>
          <MetricSnippet
            prefix="$"
            suffix="M+"
            number="150"
            description="Paid to Our Partners"
          />
        </Row>
        <Row>
          <MetricSnippet
            suffix="M+"
            number="5"
            description="Products Discovered on Our Platform"
          />
        </Row>
        <Row>
          <MetricSnippet suffix="+" number="75" description="Team Members" />
        </Row>
      </Grid>
    </Wrapper>
  );
};

export default HeroMetrics;
