import React from "react";
import styled from "styled-components";

const Item = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`;

const Iframe = (props) => {
  return (
    <Container>
      <Item {...props} frameborder="0" />
    </Container>
  );
};

export default Iframe;
