import React from "react";
import styled from "styled-components";
import { mediaMedium } from "../styles/variables";
import MainHeroTitle from "../MainHeroTitle";
import Container from "../styles/Container";
import { Eyebrows20 } from "../styles/Eyebrows";
import { graphql, useStaticQuery } from "gatsby";
import OutlinedText from "../styles/OutlinedText";
import { inject, observer } from "mobx-react";
import BackgroundImage from "gatsby-background-image";

export const Background = styled(BackgroundImage)`
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 160px 0;

    &:before,
    &:after {
      background-size: cover !important;
      background-position: center !important;
    }
  }
`;

const SubTitle = styled(Eyebrows20)`
  margin: 0 0 28px;
`;

const MainTitle = styled.span`
  display: block;
  font-size: 87px;
  line-height: 1;

  @media (${mediaMedium}) {
    font-size: 197px;
  }
`;

const LandingsSection = ({ link, theme, section, images }) => {
  return (
    <Background fluid={images.file.childImageSharp.fluid}>
      <Container>
        {section && <SubTitle>{section}</SubTitle>}
        <MainHeroTitle
          as="h1"
          theme={theme}
          link={link.attrs}
          label={link.label}
        >
          This is{" "}
          <OutlinedText fill="transparent" component={MainTitle} as="span">
            STACK
          </OutlinedText>
        </MainHeroTitle>
      </Container>
    </Background>
  );
};

const Landings = ({ store: { ui }, ...props }) => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "82-about-landing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 660, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <LandingsSection {...props} ui={ui} images={images} />;
};

export default inject(["store"])(observer(Landings));
