import React from "react";
import styled from "styled-components";
import { Headline80 } from "../styles/Headings";
import { mediaMedium } from "../styles/variables";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../Image";
import { black1, white } from "../styles/Colors";
import Container from "../styles/Container";

const Wrapper = styled.div`
  padding: 96px 0;
  background-color: ${black1};

  @media (${mediaMedium}) {
    padding: 160px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 11px;

  .gatsby-image-wrapper {
    max-width: 100% !important;
    max-height: none !important;
  }

  @media (${mediaMedium}) {
    grid-gap: 21px 11px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Row = styled.div`
  margin-bottom: 64px;

  @media (${mediaMedium}) {
    margin-bottom: 88px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  transition: 0.2s opacity;
  display: inline-block;

  &:hover {
    opacity: 0.86;
  }
`;

const imageDetails = {
  image0: {
    alt: "Instagram image: congrats on 5 years"
  },
  image1: {
    alt: "Instagram image: full team photo"
  },
  image2: {
    alt: "Instagram image: CPO recognition article snippet"
  },
  image3: {
    alt: "Instagram image: video link to instagram"
  },
  image4: {
    alt: "Instagram image: dog at work"
  },
  image5: {
    alt: "Instagram image: burgers for lunch"
  },
  image6: {
    alt: "Instagram image: 5 year employee celebration"
  },
  image7: {
    alt: "Instagram image: full team photo"
  }
};

const StackHappeningsSection = ({ images }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Headline80 theme={{ color: white }}>
            Stack <br /> happenings
          </Headline80>
        </Row>
        <Grid>
          {Object.keys(images).map((item, key) => (
            <Link
              key={"investor-" + key}
              href="https://www.instagram.com/stackcommerce/?hl=en"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <Image
                alt={imageDetails[item].alt}
                fluid={images[item].childImageSharp.fluid}
              />
            </Link>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

const StackHappenings = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "80-happenings-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(relativePath: { eq: "80-happenings-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "80-happenings-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image3: file(relativePath: { eq: "80-happenings-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image4: file(relativePath: { eq: "80-happenings-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image5: file(relativePath: { eq: "80-happenings-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image6: file(relativePath: { eq: "80-happenings-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image7: file(relativePath: { eq: "80-happenings-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 264, maxHeight: 264, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <StackHappeningsSection images={images} />;
};

export default StackHappenings;
