import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import { Headline48, Headline96 } from "../styles/Headings";
import OutlinedText from "../styles/OutlinedText";
import { Body20 } from "../styles/BodyTypes";
import { brandColor2, white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import AnimationWrapper from "../AnimationWrapper";
import { FadeIn } from "../styles/animations";

const Wrapper = styled.div`
  background-color: ${brandColor2};
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 160px 0 96px;
  }
`;

const Row = styled(FadeIn)`
  margin-bottom: 64px;

  @media (${mediaMedium}) {
    margin-bottom: 96px;
  }
`;

const Inline = styled.span`
  display: inline;
`;

const MainTitle = styled(Headline48)`
  color: ${white};
  margin: 0 0 82px;

  @media (${mediaMedium}) {
    margin-bottom: 104px;
  }
`;

const Title = styled(Headline96)`
  color: ${white};
  margin: 0;
`;

const Content = styled(Body20)`
  color: ${white};
  margin-bottom: 0;
  max-width: 552px;
`;

const Threshold = [0.4, 0.5, 0.75];

const Values = () => {
  return (
    <Wrapper>
      <Container>
        <MainTitle as="h3">CORE VALUES</MainTitle>
        <AnimationWrapper threshold={Threshold} component={Row}>
          <Title as="h4">
            ALWAYS BE
            <OutlinedText
              fill="transparent"
              color={white}
              component={Title}
              as="span"
            >
              CURIOUS
            </OutlinedText>
          </Title>
          <Content>
            Commit to figuring it out and understanding the "why." We dig deep,
            have a willingness to learn, and always strive towards our mission.
          </Content>
        </AnimationWrapper>

        <AnimationWrapper threshold={Threshold} component={Row}>
          <Title as="h4">
            SERVE
            <OutlinedText
              fill="transparent"
              color={white}
              component={Title}
              as="span"
            >
              COURAGEOUSLY
            </OutlinedText>
          </Title>
          <Content>
            We always represent and support our partners and customers. We bring
            the highest level of understanding, a spirit of collaboration, and a
            willingness to go above and beyond.
          </Content>
        </AnimationWrapper>

        <AnimationWrapper threshold={Threshold} component={Row}>
          <Title as="h4">
            EMBRACE THE
            <OutlinedText
              fill="transparent"
              color={white}
              component={Title}
              as="span"
            >
              CHALLENGE
            </OutlinedText>
          </Title>
          <Content>
            Each person has the opportunity to take charge and love what they
            do. We are players and coaches that are eager to raise our hands,
            take ownership, hustle, and adapt.
          </Content>
        </AnimationWrapper>

        <AnimationWrapper threshold={Threshold} component={Row}>
          <Title as="h4">
            ONE{" "}
            <OutlinedText
              fill="transparent"
              color={white}
              component={Inline}
              as="span"
            >
              TEAM
            </OutlinedText>
          </Title>
          <Content>
            As a company, we have many facets, but at the end of the day, we
            work together for the same cause. This is a place where honesty is
            celebrated, open mindedness is expected, integrity is valued,
            respect is given, and humility is abundant.
          </Content>
        </AnimationWrapper>
      </Container>
    </Wrapper>
  );
};

export default Values;
