import React from "react";
import styled from "styled-components";
import { linesSkew, mediaMedium } from "./styles/variables";
import { brandColor2 } from "./styles/Colors";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  pointer-events: none;
`;

const Line1 = styled.span`
  @media (${mediaMedium}) {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 35%;
    transform: skewX(${linesSkew});
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: ${(props) => props.color ?? brandColor2};
  }
`;

const Line2 = styled(Line1)`
  @media (${mediaMedium}) {
    top: 18px;
    right: calc(35% - 32px);
  }
`;

const Line3 = styled(Line1)`
  @media (${mediaMedium}) {
    top: 36px;
    right: calc(35% - 64px);
  }
`;

const LinesFromLeft = ({ color }) => {
  return (
    <Wrapper>
      <Line1 color={color} />
      <Line2 color={color} />
      <Line3 color={color} />
    </Wrapper>
  );
};

export default LinesFromLeft;
