import React from "react";
import styled from "styled-components";
import HeroTwoColumns from "../HeroTwoColumns";
import { brandColor2, brandColor3, white } from "../styles/Colors";
import LinesFromLeft from "../LinesFromLeft";
import HeroContentSnippet from "../HeroContentSnippet";
import OutlinedText from "../styles/OutlinedText";
import { mediaMedium } from "../styles/variables";

const Decoration = styled.div`
  display: none;
  pointer-events: none;

  @media (${mediaMedium}) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 45%;
    left: 0%;
    transform: translate(-58%, -21%);
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div`
  padding: 64px 18px;

  @media (${mediaMedium}) {
    padding: 88px 8px;
  }
`;

const Blog = () => {
  return (
    <HeroTwoColumns
      columns="40% 1fr"
      columnsLarge="50% 50%"
      background={brandColor2}
    >
      <div />
      <Wrapper>
        <HeroContentSnippet
          title={
            <p>
              NEVER STOP <br />
              <OutlinedText
                fill="transparent"
                stroke={white}
                as="span"
                component="span"
              >
                EARNING
              </OutlinedText>
            </p>
          }
          color={white}
          noTrail={true}
          link={{
            href: "https://blog.stackcommerce.com",
            target: "_blank",
            rel: "noopener noreferrer",
            as: "a"
          }}
          buttonVariant="white"
          label="Take Me There"
        >
          <p style={{ maxWidth: "500px" }}>
            Read our tips, tricks, and musings on all things content and
            commerce.
          </p>
        </HeroContentSnippet>
      </Wrapper>

      <Decoration>
        <LinesFromLeft color={brandColor3} />
      </Decoration>
    </HeroTwoColumns>
  );
};

export default Blog;
