import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import Iframe from "../Iframe";
import { white } from "../styles/Colors";

const LightboxWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(24, 24, 24, 0.6);
  z-index: -2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s opacity, z-index 0.2s;
  opacity: 0;
  pointer-events: none;

  &.show {
    z-index: 11;
    opacity: 1;
    pointer-events: all;

    iframe {
      display: block;
    }
  }
`;

const Container = styled.div`
  position: relative;
  max-width: 1300px;
  width: 100%;
  padding: 0 16px;
`;

const Close = styled.span`
  position: absolute;
  top: calc(-2% - 22px);
  right: -22px;
  cursor: pointer;
  z-index: 4;
  padding: 20px;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`;

const CloseBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Lightbox = ({ store: { ui } }) => {
  const closeEvent = () => {
    ui.toggleAboutVideo(false);
  };

  return (
    <LightboxWrapper className={ui.aboutVideo ? "show" : ""}>
      <CloseBackground onClick={closeEvent} />
      <Container>
        <Close onClick={closeEvent}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <g fill={white}>
                <path d="M21.2 4.22L4.21 21.2l-1.41-1.41L19.78 2.8z" />
                <path d="M4.22 2.8L21.2 19.79l-1.41 1.41L2.8 4.22z" />
              </g>
            </g>
          </svg>
        </Close>

        <Content>
          {ui.aboutVideo && (
            <Iframe src="https://player.vimeo.com/video/190758660?app_id=122963" />
          )}
        </Content>
      </Container>
    </LightboxWrapper>
  );
};

export default inject(["store"])(observer(Lightbox));
