import React from "react";
import styled from "styled-components";
import HeroTwoColumns from "../HeroTwoColumns";
import { Headline80 } from "../styles/Headings";
import { mediaMedium } from "../styles/variables";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../Image";
import { black1, brandColor3, white } from "../styles/Colors";
import LinesFromTop from "../LinesFromTop";
import AnimationWrapper from "../AnimationWrapper";
import { FadeIn } from "../styles/animations";

const Wrapper = styled.div`
  padding: 96px 20px;

  @media (${mediaMedium}) {
    padding: 169px 8px;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (${mediaMedium}) {
    grid-gap: 78px;
  }
`;

const Row = styled.div`
  margin-bottom: 80px;

  @media (${mediaMedium}) {
    margin-bottom: 118px;
  }
`;

const Decoration = styled.div`
  position: absolute;
  width: 150%;
  right: 75%;
  top: -25%;
  display: none;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const Logo = styled(Image)`
  width: 118px;

  @media (${mediaMedium}) {
    width: 60%;
  }
`;

const Threshold = [0.4, 0.5, 0.75];

const InvestorsSection = ({ images }) => {
  return (
    <HeroTwoColumns
      columns="30% 1fr"
      columnsLarge="50% 50%"
      background={black1}
    >
      <Decoration>
        <LinesFromTop color={brandColor3} />
      </Decoration>
      <div />
      <Wrapper>
        <Row>
          <Headline80 theme={{ color: white }}>OUR INVESTORS</Headline80>
        </Row>
        <Grid>
          {Object.keys(images).map((item, key) => (
            <AnimationWrapper
              key={"investor-" + key}
              threshold={Threshold}
              component={FadeIn}
            >
              <Logo fluid={images[item].childImageSharp.fluid} />
            </AnimationWrapper>
          ))}
        </Grid>
      </Wrapper>
    </HeroTwoColumns>
  );
};

const Investors = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "79-draper.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(relativePath: { eq: "79-wavemaker.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "79-amplify.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image3: file(relativePath: { eq: "79-hovc.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image4: file(relativePath: { eq: "79-pure-imagination.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image5: file(relativePath: { eq: "79-500.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <InvestorsSection images={images} />;
};

export default Investors;
